body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.collapse-button {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.collapse-button:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
  display: flex;
  justify-content: space-between;
}

.ant-dropdown-link {
  color: rgba(42, 40, 40, 0.85);
}
.ant-page-header-heading-title {
  color: #393f49;
  font-weight: 300;
  text-transform: none;
  letter-spacing: normal;
}
.ant-col {
  margin-top: -2px;
}
.ant-form-item-label {
  margin-bottom: -10px;
}
.ant-card-head {
  margin-top: -20px;
}
.ant-card-body {
  margin-top: 20px;
}
.ant-input[disabled] {
  color: black;
  background-color: #f1f1f1ab;
  cursor: not-allowed;
  opacity: 1;
  border-color: transparent;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: black;
  background: #f5f5f5;
  cursor: not-allowed;
  border-color: transparent;
}
.ant-picker-input > input[disabled] {
  color: black;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-col {
  max-width: 100% !important;
}

/* 
.rb-1 {
  background-color: #73d13d;
}
.rb-1:hover{
  background-color: #52c41a;
  color: #fff;
}

.rb-2 {
  background-color: #fadb14;
} */

/* .rb-2:not(.ant-radio-button-wrapper-disabled) {
  background-color: #d4b106 !important;
} */

/* .rb-2:hover {
  background-color: #d4b106;
  color: #fff;
} */

/* .rb-3 {
  background-color: #cf1322;
}

.rb-3:hover {
  background-color: #a8071a;
  color: #fff;
} */
